
   import Vue from 'vue';
   import LoggedIn from '@/components/LoggedIn.vue';
   import SurveyHistory from '@/components/SurveyHistory.vue';
    export default Vue.extend({
        name: "Submissions",
       components: {
           LoggedIn,
          SurveyHistory,
       }
    });
